<template>
  <div>
    <div v-if="productContentId">
      <div class="bg-light p-3 shadow rounded-2 mt-2">
        <div class="row justify-content-between">
          <div class="col-auto pe-1">
            <select v-model="status" class="form-select">
              <option value="published">published</option>
              <option value="draft">draft</option>
            </select>
          </div>
          <div class="col-auto">
            <button class="vave-btn btn-green" @click="save" :disabled="saving">
              <i class></i>
              <span v-if="saving">Saving ...</span>
              <span v-else>Save</span>
            </button>
          </div>
          <div class="col">
            <div class="row d-flex align-items-center">
              <div class="col-auto px-1">
                <button class="vave-btn" @click="exportConfig()">Export</button>
              </div>
              <div class="col-auto px-1">
                <button class="vave-btn" @click="importConfig()">Import</button>
              </div>
            </div>
          </div>

          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="vave-btn" @click="showWidgetPicker">
                  <i class="fas fa-plus"></i> Add Widget
                </button>
              </div>
              <div class="col-auto ps-1">
                <button @click="togglePreventCollision" class="vave-btn">
                  <div v-if="preventCollision">
                    <span>
                      <i class="fas fa-lock"></i> Unlock widgets
                    </span>
                  </div>
                  <div v-else>
                    <span>
                      <i class="fas fa-lock-open"></i> Lock widgets
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div class="col-auto" v-if="livePreviewActive">
            <input class="form-control" type="text" readonly :value="livePreviewUrl" />
          </div>
          <div class="col-auto" v-if="livePreviewActive">
            <button class="vave-btn btn-red" @click="endPreviewSession">Stop</button>
          </div>
          <div class="col-auto" v-if="!livePreviewActive">
            <button class="vave-btn btn-blue" @click="beginPreviewSession" :disabled="livePreviewInitialising">
              <i class="far fa-eye pe-1"></i> Start Live Preview
            </button>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <div class="bg-white shadow rounded-2">
          <div class="flex justify-between">
            <div class="flex space-x-2 items-center">
              <div>
                <div class="mr-2"></div>
              </div>
            </div>

            <div></div>
          </div>

          <grid-layout
            :layout.sync="layout"
            :is-draggable="draggable"
            :col-num="12"
            :row-height="200"
            :is-resizable="resizable"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[10, 10]"
            :use-css-transforms="true"
            :responsive="false"
            :prevent-collision="preventCollision"
            class="bg-white mt-2 rounded shadow"
          >
            <grid-item v-bind:key="item.i" v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :isResizable="item.isResizable" :minW="item.minW" :maxW="item.maxW" :minH="1" :maxH="1" :ref="'widget-' + item.i">
              <component :is="item.contains" :widget="item.widgetConfig" @loadedTemplate="updateConfig(item, $event)" :icon="item.icon" @delete="deletewidget(item)" @duplicate="duplicate(item)"></component>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <portal-target name="widget-modals" multiple></portal-target>

      <widget-config-modal ref="widgetPicker">
        <template v-slot:title>Add widget</template>
        <template v-slot:subtitle>Add a widget to the grid</template>
        <template v-slot:body>
          <div v-for="widget in library" :key="widget.i" class="widget-item d-flex justify-content-between align-items-center" :class="{'deprecated': isWidgetDeprecated(widget)}" @click="addWidget(widget, $event)">
            <div class="p-3 d-flex align-items-center">
              <div style="width: 240px" class="title d-flex align-items-center">
                <i class="d-flex justify-content-center pe-4" :class="widget.icon"></i>
                <div style="font-size:1.3em;">{{ widget.contains }}</div>
              </div>
              <div>
                <div class="description">{{ widget.description }}</div>
                <span class="deprecation-note" v-if="isWidgetDeprecated(widget)">(deprecated: use custom-banner instead)</span>
              </div>
            </div>
            <div>
              <button class="vave-btn btn-blue">
                <i class="fas fa-plus" style="pointer-events: none"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-slot:footer></template>
      </widget-config-modal>
    </div>
    <div v-else>
      Loading the Product Content...
      <!-- loading spinner -->
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import _ from "lodash";
import EventBus from "./../bus.js";
import axios from "./../http.js";
const uuidv4 = require("uuid/v4");
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import RawHtml from "./widgets/RawHtml";
import Banner from "./widgets/Banner";
import Products from "./widgets/Products";
import Hero from "./widgets/Hero";
import CustomBanner from "./widgets/CustomBanner";
import NewHero from "./widgets/NewHero";
import NewBanner from "./widgets/NewBanner";
import Brands from "./widgets/Brands";
import Categories from "./widgets/Categories";
import Blog from "./widgets/Blog";
import Prefab from "./widgets/Prefab";
import TextEditor from "./widgets/TextEditor";
import CarbonCalculator from "./widgets/CarbonCalculator";
import ActiveCampaign from "./widgets/ActiveCampaign";
import textWithImage from "./widgets/textWithImage";
import imageBlock from "./widgets/image";
import spacer from "./widgets/spacer";
import Faqs from "./widgets/Faq";
import availableWidgets from "../availableWidgets.js";
import moment from "moment";
import hash from "object-hash";
import MqttClient from "./../mqtt.js";

export default {
  components: {
    GridLayout,
    GridItem,
    WidgetConfigModal,
    CustomBanner,
    RawHtml,
    Banner,
    NewBanner,
    Hero,
    NewHero,
    Brands,
    Categories,
    ActiveCampaign,
    TextEditor,
    CarbonCalculator,
    Products,
    Blog,
    Prefab,
    textWithImage,
    imageBlock,
    spacer,
    Faqs,
  },
  data() {
    return {
      topic: "",
      livePreviewActive: false,
      sharePreviewActive: false,
      livePreviewUrl: "",
      livePreviewInitialising: false,
      status: "",
      triggerRegistrationEvent: false,
      saving: false,
      drafting: false,
      layout: [],
      productContentId: null,
      draggable: true,
      resizable: true,
      index: 0,
      pageData: {},
      currentLowestObject: -1,
      preventCollision: true,
      layoutMd5: "",
      exportIncludeLayout: true,
    };
  },
  mounted() {
    this.loadPage();
    // filter only images and text widgets
    this.checkActiveLivePreview();
    window.addEventListener("keydown", this.saveWithKeyboard);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.saveWithKeyboard);
  },
  computed: {
    library() {
      // move the deprecated widgets at the bottom
      return _.sortBy(availableWidgets, (widget) => {
        return this.isWidgetDeprecated(widget) ? 1 : 0;
      });
    },
  },
  props: ["modelId", "modelType"],
  watch: {
    layout: {
      deep: true,
      handler(layout) {
        // Calculate the current lowest object, so we can insert the next one under it
        var epsilons = [-1];
        _.forEach(layout, (widget) => {
          epsilons.push(widget.y);
        });
        this.currentLowestObject = Math.max(...epsilons);
        if (this.livePreviewActive) {
          let newLayoutHash = hash(layout);
          if (this.layoutMd5 !== newLayoutHash) {
            this.layoutMd5 = newLayoutHash;
            MqttClient.publish(
              this.topic + "/update",
              JSON.stringify(this.layout)
            );
          }
        }
      },
    },
    $route() {
      this.loadPage();
    },
  },
  filters: {
    formattedDate(value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mm");
    },
  },
  methods: {
    saveWithKeyboard(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        this.save();
      }
    },
    isWidgetDeprecated(widget) {
      return ["banner", "new-banner", "hero", "new-hero"].includes(
        widget.contains
      );
    },
    updateConfig(item, templateConfig) {
      item.widgetConfig = templateConfig;
    },
    checkActiveLivePreview() {
      if (this.$store.state.livePreviewSessionId) {
        this.beginPreviewSession(false);
      }
    },
    togglePreventCollision() {
      this.preventCollision = !this.preventCollision;
    },
    beginPreviewSession(openSessionWindow = false) {
      if (this.livePreviewInitialising === true) return;
      this.livePreviewInitialising = true;

      let sessionID = this.$store.state.livePreviewSessionId
        ? this.$store.state.livePreviewSessionId
        : uuidv4();

      this.$store.commit("setLivePreviewSession", sessionID);

      this.topic = "rtp-" + sessionID;

      MqttClient.subscribe(this.topic + "/hello");
      EventBus.$on(this.topic + "/hello", () => {
        MqttClient.publish(this.topic + "/update", JSON.stringify(this.layout));
      });

      this.livePreviewActive = true;
      this.livePreviewUrl =
        process.env.VUE_APP_PWAURL + "/preview?s=" + sessionID;
      if (openSessionWindow) {
        window.open(this.livePreviewUrl).focus();
      }
      this.livePreviewInitialising = false;
    },
    endPreviewSession() {
      if (confirm("Do you want to end the live preview?")) {
        this.livePreviewUrl = null;
        this.livePreviewActive = false;
        this.livePreviewInitialising = false;
        this.$store.commit("setLivePreviewSession", null);
      }
    },
    sharePreviewLink() {
      this.sharePreviewActive = !this.sharePreviewActive;
    },
    showWidgetPicker() {
      this.$refs.widgetPicker.show();
    },
    addWidget(widget) {
      if (
        this.isWidgetDeprecated(widget) &&
        !confirm(
          "This widget is deprecated and will be removed in the future. Are you sure you want to add it?"
        )
      ) {
        return;
      }
      var newWidget = _.cloneDeep(widget);
      newWidget.i = uuidv4();
      newWidget.y = this.currentLowestObject + 1;
      this.layout.push(newWidget);
      this.$refs.widgetPicker.hide();
      this.$nextTick(() => {
        // Scroll to the element and make it glow
        setTimeout(() => {
          var w = this.$refs["widget-" + newWidget.i][0];
          w.$el.scrollIntoView({ behavior: "smooth" });
          w.$el.classList.add("slowglow");
          w.$el.classList.add("glow");
          setTimeout(() => {
            w.$el.classList.remove("glow");
          }, 1150);
        }, 350);
      });
    },
    duplicate(item) {
      var newWidget = _.cloneDeep(item);
      newWidget.i = uuidv4();
      this.layout.push(newWidget);
    },
    loadPage() {
      axios
        .get("/api/admin/product-contents", {
          params: {
            model_id: this.modelId,
            model_type: this.modelType,
          },
        })
        .then((response) => {
          this.status = response.data.data.status;
          if (response.data.data.id) {
            this.productContentId = response.data.data.id;
            let data = response.data.data;
            this.pageData = response.data.data;
            this.layout = [];
            this.$nextTick(() => {
              if (data.content && data.content.length !== 0) {
                this.layout = data.content;
              }
            });
          } else {
            // Create a new product content
            this.layout = [];
            this.saving = true;
            axios
              .post("/api/admin/product-contents", {
                content: [],
                model_id: this.modelId,
                model_type: this.modelType,
              })
              .then((response) => {
                let data = response.data.data;
                this.productContentId = response.data.data.id;
                this.pageData = response.data.data;
                this.layout = [];
                this.$nextTick(() => {
                  if (data.content && data.content.length !== 0) {
                    this.layout = data.content;
                  }
                });
              })
              .finally(() => {
                setTimeout(() => {
                  this.saving = false;
                }, 500);
              });
          }
        });
    },

    deletewidget(item) {
      this.layout.forEach((subject) => {
        if (subject.i == item.i) {
          var index = this.layout.indexOf(item);
          if (index !== -1) {
            this.layout.splice(index, 1);
          }
        }
        // Reset the index of the items
        this.layoutMd5 = hash(this.layout);
      });
    },
    save() {
      this.saving = true;
      axios
        .put("/api/admin/product-contents/" + this.productContentId, {
          status: this.status,
          content: this.layout,
        })
        .then(() => {
          this.$toast.success("Page saved", {
            hideProgressBar: true,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    exportConfig() {
      var data = {};
      if (this.exportIncludeLayout) {
        data.layout = this.layout;
      }
      var blob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });
      var url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "config.json";
      a.click();
      // Hide the modal and reset the checkbox
      this.$refs.exportConfigModal.hide();
      this.exportIncludeLayout = true;
    },
    importConfig() {
      var input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          var data = JSON.parse(e.target.result);
          if (data.layout) {
            this.layout = data.layout;
            this.$toast.success("Page imported");
          }
        };
        reader.readAsText(file);
      };
      input.click();
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: none;
  border-radius: 3px;
  overflow: hidden;

  .header {
    background: rgba(black, 0.2);
    padding: 10px;
    margin-bottom: 10px;
  }
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}

.widget-text-content {
  color: white;
  background: rgba(black, 0.8);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 4px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;

  h1 {
    font-size: 1.4em;
  }
  h2 {
    font-size: 0.9em;
  }
}
.fakebuton {
  background: #feb103;
  color: black;
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.notShowing {
  max-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: all 250ms ease-in-out;

  &.showing {
    max-width: 300px;
    overflow: auto;
    opacity: 1;
  }
}

.flatEdge {
  border-radius: 0;
}
.transition {
  transition: all 250ms ease-in-out;
}
.success {
  color: green;
}

.slowglow {
  transition: all 500ms ease-in-out !important;
}
.glow {
  box-shadow: 0px 0px 10px 10px #fff, 0px 0px 1000px 1000px rgba(0, 0, 0, 0.8);
}
.widget-item {
  cursor: pointer;
  border-bottom: 1px solid #fafafa;
  padding-right: 10px;
  &:hover {
    background: #fafafa;
  }

  .title {
    text-transform: capitalize;
  }
  &.deprecated {
    background: #eeeeee;
    .description {
      opacity: 0.5;
      text-decoration: line-through;
    }
    .title {
      opacity: 0.5;
      text-decoration: line-through;
    }
    button {
      opacity: 0.5;
    }
  }
}
</style>