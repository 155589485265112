<template>
  <div>
    <div class="container background">
      <div class="row align-items-center">
        <div class="col"></div>
        <div class="col-auto">
          <router-link :to="{ name: 'products' }" class="mb-4 vave-btn">BACK TO PRODUCTS</router-link>
        </div>
      </div>
      <div class="d-flex flex-column" v-if="productReady">
        <h3>{{product.name}}</h3>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <img v-if="product.images[0]" :src="image(product.images[0].origin_src, 100)" alt loading="lazy" class="image" width="100px" />
          </div>

          <div class="col-3 d-flex flex-column">
            <div>Blog Shortcode</div>
            <div class="input-group">
              <input type="text" class="form-control" disabled ref="shortcode" :value="'[showproduct id=' + product.remote_id + ']'" />
              <button class="btn btn-outline-success" type="button" @click="copyShortcode">
                <i class="fa fa-copy"></i>
              </button>
            </div>
          </div>
          <div class="d-flex">
            <button @click="getProduct()" class="vave-btn btn-outline-blue me-1" :disabled="disableRefreshButton">Refresh</button>
            <button @click="syncProduct()" class="vave-btn btn-green" :disabled="disableSyncButton">Synchronize product</button>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-12">
            <h4>Product Page Content</h4>
          </div>
          <div class="col-12">
            <page-content-editor modelType="product" :modelId="product.id" v-if="product.id" />
          </div>
        </div>

        <div class="video-editor">
          <h4>Product Video</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-floating">
                <input type="text" name="title" v-model="product.video.video_url" class="form-control" placeholder="Video URL" />
                <label for="title">Video URL</label>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="form-floating">
                <input type="text" name="title" v-model="product.video.poster_url" class="form-control" placeholder="Poster URL" />
                <label for="title">Poster URL</label>
              </div>
            </div>
            <div class="col-12 d-flex">
              <button class="vave-btn btn-blue me-2" :disabled="product.video.video_url == false || product.video.poster_url == false" :class="{disabled: product.video.video_url == false || product.video.poster_url == false}" @click="saveVideo">Save Video Options</button>
              <button class="vave-btn btn-red" :disabled="!product.video.id" :class="{disabled: !product.video.id}" @click="deleteVideo">Delete Video</button>
            </div>
          </div>
        </div>

        <div class="table">
          <table class="table" v-if="synchronization.id">
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Arguments</th>
                <th>Started at</th>
                <th>Finished at</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{synchronization.id}}</td>
                <td>{{synchronization.type}}</td>
                <td>{{synchronization.arguments}}</td>
                <td>{{synchronization.started_at}}</td>
                <td>{{synchronization.finished_at}}</td>
                <td>
                  <span
                    class="badge"
                    :class="{
                  'bg-secondary': synchronization.status == 'pending',
                  'bg-primary': synchronization.status == 'in_progress',
                  'bg-success': synchronization.status == 'succeeded',
                  'bg-danger': synchronization.status == 'failed',
                }"
                  >{{synchronization.status}}</span>
                </td>
                <td>
                  <div class="vave-btn btn-small btn-blue" @click="showOutput(synchronization.id), showWidgetPicker()" title="show job output">
                    <i class="fa fa-eye"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="table table-bordered">
          <tbody>
            <tr v-for="p, k in product" :key="k">
              <td style="text-transform: capitalize;">{{k | keyed}}</td>
              <td v-if="isObject(p)">{{p}}</td>
              <td v-else>{{p}}</td>
            </tr>
          </tbody>
        </table>

        <div v-for="variation, k in variations" :key="k">
          <h5>VARIATION - {{variation.name}}</h5>
          <table class="table table-bordered">
            <tbody>
              <tr v-for="v, k in variation" :key="k">
                <td style="text-transform: capitalize;">{{k | keyed}}</td>
                <td v-if="isObject(v)">{{v}}</td>
                <td v-else>{{v}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr />

        <div class="section">
          <button class="vave-btn btn-blue" v-on:click="toggle">Show Raw Data</button>
          <div class="body" v-show="showSection">
            <pre class="code mt-2">{{product}}</pre>
            <pre class="code mt-2">{{variations}}</pre>
          </div>
        </div>
      </div>
    </div>

    <widget-config-modal ref="widgetPicker">
      <template v-slot:title>Output</template>
      <template v-slot:body>
        <div style="border:1px solid black;padding: 20px;">
          <div style="overflow: auto;">
            <span style="white-space: pre;">{{output}}</span>
          </div>
        </div>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import axios from "./../http.js";
import MqttClient from "./../mqtt.js";
import EventBus from "./../bus.js";
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import PageContentEditor from "./PageContentEditor";
import imageProxy from "./../imageProxy";

export default {
  data() {
    return {
      product: {},
      variations: [],
      synchronization: {},
      productReady: false,
      disableRefreshButton: false,
      disableSyncButton: false,
      synchronizationId: null,
      showSection: false,
      output: "",
    };
  },
  filters: {
    keyed(value) {
      return value.replace(/_/g, " ").trim();
    },
  },
  beforeDestroy() {
    EventBus.$off("globus/vaveproxy/synchronizations/#", () => {
      this.getSynchronization();
      this.getProduct();
    });
  },
  mounted() {
    this.getProduct();
    let topic = "globus/vaveproxy/synchronizations/#";
    MqttClient.subscribe(topic);
    EventBus.$on(topic, () => {
      this.getSynchronization();
      this.getProduct();
    });
  },
  components: { WidgetConfigModal, PageContentEditor },
  methods: {
    deleteVideo() {
      if (this.product.video.id) {
        axios.delete("/api/admin/videos/" + this.product.video.id).then(
          () => {
            this.$toast.success("Video deleted", {
              position: "top-right",
              timeout: 1500,
            });
          },
          () => {
            this.$toast.error("Video delete error", {
              position: "top-right",
              timeout: 1500,
            });
          }
        );
      }
    },
    saveVideo() {
      if (this.product.video.video_url && this.product.video.poster_url) {
        var url = "/api/admin/videos/";
        var method = "post";
        if (this.product.video.id) {
          url += this.product.video.id;
          method = "put";
        }
        axios[method](url, {
          video_url: this.product.video.video_url,
          poster_url: this.product.video.poster_url,
          product_id: this.product.id,
        }).then(
          () => {
            this.$toast.success("Video saved", {
              position: "top-right",
              timeout: 1500,
            });
          },
          () => {
            this.$toast.error("Video save error", {
              position: "top-right",
              timeout: 1500,
            });
          }
        );
      } else {
        this.$toast.error("Enter video and poster url", {
          position: "top-right",
          timeout: 1500,
        });
      }
    },
    image: imageProxy.image,

    isObject(val) {
      return val instanceof Object;
    },
    toggle() {
      this.showSection = !this.showSection;
    },
    syncProduct() {
      if (!confirm("do you want to synch the product?")) {
        return;
      }
      this.disableSyncButton = true;
      axios
        .post("/api/admin/synchronizations", {
          type: "sync:products",
          arguments: String(this.product.remote_id),
        })
        .then(
          (r) => {
            if (r.status == 201) {
              this.synchronizationId = r.data.id;
              setTimeout(() => {
                this.disableSyncButton = false;
              }, 1000);
              this.$toast.success("Synchronization started", {
                position: "top-right",
                timeout: 1500,
              });
            }
            this.getSynchronization();
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
    getSynchronization() {
      this.output = "";
      this.synchronization = [];
      axios
        .get("/api/admin/synchronizations/" + this.synchronizationId)
        .then((response) => {
          if (response.data) {
            this.synchronization = response.data;
          }
        });
    },
    getProduct() {
      this.disableRefreshButton = true;
      axios.get("/api/products/" + this.$route.params.id).then(
        (r) => {
          if (r.status == 200) {
            this.product = r.data.data;
            if (this.product.video == null) {
              this.product.video = {
                video_url: null,
                poster_url: null,
                product_id: this.product.id,
              };
            }

            // Get the variations for this product
            axios
              .get("/api/products/" + this.$route.params.id + "/variations")
              .then(
                (r) => {
                  if (r.status == 200) {
                    this.variations = r.data.data;
                    setTimeout(() => {
                      this.disableRefreshButton = false;
                    }, 1000);
                    this.$toast.success("Product refreshed", {
                      position: "top-right",
                      timeout: 1500,
                    });
                  }
                  this.productReady = true;
                },
                (e) => {
                  console.log(e);
                }
              );
          }
          this.productReady = true;
        },
        (e) => {
          console.log(e);
        }
      );
    },
    showWidgetPicker() {
      this.$refs.widgetPicker.show();
    },
    showOutput() {
      this.output = this.formatOutput(this.synchronization.output);
    },
    formatOutput(data) {
      return data;
    },

    copyShortcode() {
      let shortcode = this.$refs.shortcode.value;
      var that = this;
      navigator.clipboard
        .writeText(shortcode)
        .then(function () {
          that.$toast.success("Shortcode " + shortcode + " copied", {
            position: "top-right",
            timeout: 1500,
          });
        })
        .catch(function () {
          that.$toast.error("Could not copy", {
            position: "top-right",
            timeout: 1500,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
.code {
  background: #dadada;
  border-radius: 10px;
  padding: 20px;
}
.image {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
}

.video-editor {
  padding: 10px;
  background: rgba(black, 0.2);
}
</style>